<template>
  <div></div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      options: {},
      headers: [
        { text: "Name", value: "name" },
        { text: "Type", value: "type" }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.synonyms.errors,
      message: state => state.synonyms.message,
      hasMessage: state => state.synonyms.hasMessage,
      synonyms: state => state.synonyms.synonyms_list,
      loading: state => state.synonyms.loading
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    reloadData() {}
  }
};
</script>
